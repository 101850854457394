import React from 'react';
import {
  DontDo,
  Link,
  List,
  PageHero,
  PageNavigation,
  PageWithSubNav,
  Paragraph,
  PlatformTable,
  Section,
  SectionSubhead,
} from '../../../../components';
import pageHeroData from '../../../../data/pages/guidelines.yml';

const IndexPage = () => {
  return (
    <PageWithSubNav
      pageType="design"
      title="Elevation"
      designCode
      subnav="guidelines">
      <PageHero
        heroData={pageHeroData}
        tierTwo="Layering"
        tierThree="Elevation"
      />

      <PageNavigation
        links={[
          'Stack Order',
          'Levels vs. Layering',
          'Mobile',
          'Usage',
          'Platform',
        ]}
      />

      <Section title="Stack Order">
        <Paragraph>
          Common interface elements are layered, with each level indicating
          importance and priority to the user. This stack order is consistent
          across Hudl interfaces.
        </Paragraph>

        <img
          src={require('../../../../imgs/diagrams/layering-stackorder.gif')}
          className="uni-padding--one--bottom"
        />

        <SectionSubhead>Tangents</SectionSubhead>
        <Paragraph>
          A tangent is a piece of content (
          <Link href="/components/tooltip/design" isDesignCode>
            tooltip
          </Link>
          , dropdown, popover) that does not have a set elevation, but needs to
          sit above other content in its current elevation. An example of this
          is a tooltip within a modal (layer 3).
        </Paragraph>

        <img
          src={require('../../../../imgs/diagrams/layering-tangent.png')}
          className="uni-padding--one--bottom"
        />

        <SectionSubhead>Scrim</SectionSubhead>
        <Paragraph>
          A scrim is a transparent overlay placed just beneath a layered
          element. Its purpose is to dim and obfuscate content underneath the
          layered element. This focuses the user’s attention on the layered
          element in question. Scrims are required for layer 3 (modals), but
          optional for layer 2 elements like prompts,{' '}
          <Link href="/components/notifications/toast" isDesignCodeLink>
            toasts
          </Link>{' '}
          and{' '}
          <Link href="/components/action-bar" isDesignCodeLink>
            action bars
          </Link>
          .
        </Paragraph>
      </Section>

      <Section title="Levels vs. Layering">
        <Paragraph>
          It’s important to distinguish{' '}
          <Link href="/guidelines/colors/themes" isDesignCodeLink>
            theming environment levels
          </Link>{' '}
          from the concept of layering. Levels affect the background and/or
          border color of a given element, whereas layering physically places
          one element on top of another, like z-indexing.
        </Paragraph>
        <Paragraph>
          Levels create separation between regions on the same plane, while
          layering creates a new dimension altogether. For instance, you might
          paint your living room and upstairs bedroom the same color, but color
          is where the connection ends. Otherwise they’re totally separate,
          physically and in purpose.
        </Paragraph>
        <Paragraph>
          The dimension of layers is visually accentuated with the{' '}
          <Link href="/guidelines/layering/shadows" isDesignCodeLink>
            shadows
          </Link>{' '}
          applied to each.
        </Paragraph>
      </Section>
      <Section title="Mobile">
        <Paragraph>
          Layering elevation still applies to design on mobile but is approached
          differently. For example,{' '}
          <Link href="/components/dialogs/modal/design">modals</Link> used in
          iOS make use of{' '}
          <Link href="https://developer.apple.com/design/human-interface-guidelines/ios/app-architecture/modality/">
            modal view
          </Link>
          , which applies elevation and{' '}
          <Link href="/guidelines/layering/shadows/design">shadow</Link> to the
          entire view. Whereas Android depicts the layer with a{' '}
          <Link href="https://material.io/design/environment/elevation.html#depicting-elevation">
            scrim
          </Link>
          . Be cognizant of each platform when designing elevation.
        </Paragraph>
      </Section>

      <Section title="Usage">
        <SectionSubhead>Stack Order</SectionSubhead>
        <Paragraph>
          The stack order ensures similar interface elements like toasts, modals
          and action bars have a consistent elevation across all Hudl product
          interfaces.
        </Paragraph>

        <DontDo
          dontText="put elements on the wrong layer."
          doText="follow the correct stacking order."
          imgFilename="elevation-wrong-layer"
        />

        <DontDo
          dontText="use shadows to “elevate” levels."
          doText="apply shadows to layered elements."
          imgFilename="elevation-shadows"
        />
      </Section>

      <Section title="Platform">
        <PlatformTable
          platforms={{
            apple: (
              <List>
                <li>
                  Follow{' '}
                  <Link href="https://developer.apple.com/ios/human-interface-guidelines">
                    Human Interface guidelines
                  </Link>
                  .
                </li>
              </List>
            ),
            android: (
              <List>
                <li>
                  Follow{' '}
                  <Link href="https://material.io/guidelines">
                    Material guidelines
                  </Link>
                  .
                </li>
              </List>
            ),
          }}
        />
      </Section>
    </PageWithSubNav>
  );
};

export default IndexPage;
